import React, { FC, useEffect, useState } from "react"
import { useRouter } from "next/router"
import { AlertType } from "@interfaces/ui"
import { Modal } from "@components/modal"
import { Alert } from "@components/alert"

export interface AlertHandlerProps {
  message: string
  type?: AlertType
  redirect?: string
  modalTitle?: string
  className?: string
}

export const AlertHandler = ({
  message,
  type,
  redirect,
  modalTitle,
  className,
}: AlertHandlerProps) => {
  const [alertError, setAlertError] = useState(false)
  const [alertSuccess, setAlertSuccess] = useState(false)
  const [modalSuccess, setModalSuccess] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  // const [timeoutResponse, setTimeoutResponse] = useState(0);

  const router = useRouter()

  useEffect(() => {
    if (message) {
      setAlertMessage(message)

      switch (type) {
        case "error":
          setAlertError(true)
          break
        case "success-alert":
          setAlertSuccess(true)
          break
        case "success-modal":
          setModalSuccess(true)
          break

        default:
          break
      }
    } else {
      handleCloseAlert()
    }
  }, [message, type])

  async function handleCloseManual(): Promise<void> {
    if (redirect) {
      window.location.href = redirect
    } else {
      router.reload()
    }
  }

  function handleCloseAlert(): void {
    setAlertError(false)
    setAlertSuccess(false)
    setAlertMessage("")
  }

  return (
    <>
      <Alert
        variant="success"
        onClose={handleCloseAlert}
        closed={!alertSuccess}
        className={className ?? ""}
      >
        {alertMessage}
      </Alert>
      <Alert
        variant="danger"
        onClose={handleCloseAlert}
        closed={!alertError}
        className={className ?? ""}
      >
        {alertMessage}
      </Alert>

      <Modal
        show={modalSuccess}
        onClose={handleCloseManual}
        closeButton
        contentClassName="!rounded-none sm:min-w-[35vw]"
      >
        <h3 className="text-center whitespace-pre-wrap text-black-22 text-2xl font-bold sm-only:text-base">
          {modalTitle}
        </h3>
        <p className="text-center whitespace-pre-wrap sm-only:text-sm">{message}</p>
      </Modal>
    </>
  )
}
