import React from "react"
import Image from "next/image"
import { BasicLink } from "@components/typography"
import { HARISENIN_LOGO } from "@constants/const/pictures"

const HeaderAuth = () => {
  return (
    <header className="p-4 sticky w-full top-0 z-50 bg-white border-b border-b-grey-ec">
      <div className="container mx-auto flex">
        <BasicLink href="/" className="h-full">
          <Image src={HARISENIN_LOGO} alt="harisenin.com-logo" width={178} height={26} />
        </BasicLink>
      </div>
    </header>
  )
}

export default HeaderAuth
