import ky from "ky"

export default class GoogleServices {
  async checkCaptcha(captchaToken: string) {
    const checkCaptcha = await ky.post("/api/google/recaptcha", {
      json: { response: captchaToken },
    })

    return checkCaptcha.ok
  }
}
