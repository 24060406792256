import { UserPopupData } from './../../../interfaces/ui';
import { Result } from "@services/response"
import { Alumnus, AlumnusSector, CertificateInfo, FAQ, PaginationData } from "@interfaces/misc"
import MiscRepositories from "../repositories/misc.repositories"
import { Testimonial } from "@interfaces/school"
import { Tutor } from "@interfaces/coding-camp"
import { Product } from '@interfaces/misc';

export default class MiscServices extends MiscRepositories {
  async getAlumnus(query: any) {
    try {
      const res = await this.getRawAlumnus(query)
      const response = await this._getResponse<PaginationData<Alumnus>>(res)

      const { error, result } = response

      if (error) {
        return Result.fail<PaginationData<Alumnus>>()
      }

      return Result.ok<PaginationData<Alumnus>>(result)
    } catch (e: any) {
      return Result.fail<PaginationData<Alumnus>>()
    }
  }

  async getCertificateDetail(id: string) {
    try {
      const res = await this.getRawCertificateInfo(id)
      const response = await this._getResponse<CertificateInfo>(res)

      const { error, result } = response

      if (error || !result?.valid) {
        return Result.fail<CertificateInfo>()
      }

      return Result.ok<CertificateInfo>(result)
    } catch (e: any) {
      return Result.fail<CertificateInfo>()
    }
  }

  async getTestimonials(type: string) {
    try {
      const res = await this.getRawTestimonials(type)
      const response = await this._getResponse<Testimonial[]>(res)

      if (response.error) {
        return Result.fail<Testimonial[]>()
      }

      return Result.ok<Testimonial[]>(response.result)
    } catch (e) {
      return Result.fail<Testimonial[]>()
    }
  }

  async getFaq(type: string) {
    try {
      const res = await this.getRawFaqs(type)
      const response = await this._getResponse<FAQ[]>(res)

      const { error, result } = response

      if (error) {
        return Result.fail<FAQ[]>()
      }

      return Result.ok<FAQ[]>(result)
    } catch (e: any) {
      return Result.fail<FAQ[]>()
    }
  }

  async getTutors() {
    try {
      const res = await this.getRawTutors()
      const response = await this._getResponse<Tutor[]>(res)

      if (response.error) {
        return Result.fail<Tutor[]>()
      }

      return Result.ok<Tutor[]>(response.result)
    } catch (e) {
      return Result.fail<Tutor[]>()
    }
  }

  async getAlumnusSector() {
    try {
      const res = await this.getRawAlumnusSector()
      const response = await this._getResponse<AlumnusSector[]>(res)

      const { error, result } = response

      if (error) {
        return Result.fail<AlumnusSector[]>()
      }

      return Result.ok<AlumnusSector[]>(result)
    } catch (e: any) {
      return Result.fail<AlumnusSector[]>()
    }
  }

  async getOrderAndAlumnus() {
    try {
      const res = await this.getRawOrderAndAlumnus()
      const response = await this._getResponse<UserPopupData[]>(res)

      const { error, result } = response

      if (error) {
        return Result.fail<UserPopupData[]>()
      }

      return Result.ok<UserPopupData[]>(result)
    } catch (e: any) {
      return Result.fail<UserPopupData[]>()
    }
  }

  async getDigitalProducts() {
    try {
      const res = await this.getRawProducts({limit: 6})
      const response = await this._getResponse<PaginationData<Product>>(res)

      const { error, result } = response

      if (error) {
        return Result.fail<PaginationData<Product>>()
      }

      return Result.ok<PaginationData<Product>>(result)
    } catch (e: any) {
      return Result.fail<PaginationData<Product>>()
    }
  }
}
