import React, { useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import AuthServices from "@services/auth.services"
import Helmet from "@helpers/Helmet"
import { AlertHandler } from "@components/alert"
import clsx from "clsx"
import LeftPictureRegistrationPage from "../components/LeftPictureRegistrationPage"
import { loginValidationSchema } from "../const/validation"
import { RawTokenData } from "@interfaces/auth"
import { GoogleLoginButton, RegularButton } from "@components/button"
import { FlexBox } from "@components/wrapper"
import GoogleServices from "@lib/api/services/google.services"
import { FormInput } from "@components/input/react-hook-form/FormInput"
import HeaderAuth from "./HeaderAuth"
import { useForm } from "react-hook-form"
import { useAnalytics } from "@lib/context/AnalyticsContext"
import { isInAppChecker } from "@lib/functions"
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

const LoginPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { handleSubmit, control, setError } = useForm({
    mode: "onSubmit",
    // @ts-ignore
    resolver: yupResolver(loginValidationSchema),
  })
  const [alertMessage, setAlertMessage] = useState("")

  const auth = new AuthServices()
  const google = new GoogleServices()
  const isInApp = isInAppChecker()
  // const { analytics } = useAnalytics()
  // const { executeRecaptcha } = useGoogleReCaptcha()

  function errorMessageGenerator(msg: string) {
    setAlertMessage(msg)
  }

  const errorValidationServer = (field: string, message: string) => {
    setError(field, {
      type: "manual",
      message: message,
    })
  }

  const onSubmit = async (data: any) => {
    setIsLoading(true)
    setAlertMessage("")
    console.log(data)
    try {
      // const captchaToken = await executeRecaptcha("enquiryFormSubmit")
      // const checkCaptcha = await google.checkCaptcha(captchaToken)

      // if (!checkCaptcha) {
      //   setIsLoading(false)
      //   return setAlertMessage(
      //     "Terjadi kesalahan pada validasi. Silahan muat ulang page lalu coba lagi"
      //   )
      // }

      const res = await auth.manualLogin(data)

      if (res.isFailure) {
        const err = res.error
        switch (err.message) {
          case "Email belum terdaftar":
            errorValidationServer("email", err.message)
            break
          case "Password yang kamu masukkan salah":
            errorValidationServer("password", err.message)
            break
          default:
            break
        }
        setIsLoading(false)
      } else {
        const result = res.getValue() as RawTokenData
        // analytics.track("Signed In", {
        //   method: "email",
        // })

        window.location.href = "/"
      }
    } catch (e: any) {
      if (e.message) {
        setAlertMessage(e.message)
      } else {
        setAlertMessage("Terjadi kesalahan. Cek kembali data yang diinput dan coba lagi")
      }
      setIsLoading(false)
    }
  }

  return (
    <>
      <Helmet title="Harisenin.com: Bergabung bersama harisenin.com" isIndexed />

      <FlexBox className="relative overflow-hidden" direction="col">
        <HeaderAuth />
        <AlertHandler
          message={alertMessage}
          redirect="/"
          type="error"
          className="mt-5 left-[5%] absolute"
        />
        <div className={clsx("sm:flex relative overflow-hidden", "flex-1")}>
          <LeftPictureRegistrationPage />
          <div
            className={clsx(
              "sm:w-2/5 overflow-auto sm:h-[calc(100vh-60px)]",
              "sm:p-[82px] px-5 py-7.5"
            )}
          >
            <form onSubmit={handleSubmit(onSubmit)} className="">
              <h1 className={clsx("sm:text-[30px] font-bold mb-1", "text-xl")}>
                Sudah Punya Akun?
              </h1>
              <p className={clsx("mb-4", "sm-only:text-sm")}>Langsung masuk aja, ya!</p>

              {!isInApp && (
                <>
                  <GoogleLoginButton
                    redirect="/"
                    handleError={errorMessageGenerator}
                    id="btn-login-with-google"
                    copyText="Masuk dengan Google"
                  />

                  <div className="h-3 mt-6 mb-8 text-center border-b-grey-ec border-b">
                    <span className="bg-white px-3.5 text-sm">atau</span>
                  </div>
                </>
              )}
              <FlexBox direction="col" className="gap-5">
                <FormInput
                  control={control}
                  name="email"
                  label="E-mail"
                  type="email"
                  theme="blue"
                />
                <FormInput
                  control={control}
                  name="password"
                  label="Password"
                  type="password"
                  theme="blue"
                />
              </FlexBox>
              <div className="flex justify-end mt-4">
                <a
                  href={"/lupa-password"}
                  className="text-new-green font-semibold text-sm"
                  id="btn-forget-password-modal"
                >
                  Lupa Password?
                </a>
              </div>
              <RegularButton
                isSubmitting={isLoading}
                onClick={onSubmit}
                isFormik
                id="btn-submit-login"
                className="my-5 w-full"
                customPadding="py-2.5"
                variant="new-blue"
              >
                Masuk
              </RegularButton>

              <FlexBox justify="center" className="text-sm">
                Belum punya akun?{" "}
                <a
                  href={`/register`}
                  className="ml-1.5 text-new-green font-semibold"
                  id="btn-regist-modal"
                >
                  Daftar sekarang
                </a>
              </FlexBox>
            </form>
          </div>
        </div>
      </FlexBox>
    </>
  )
}

export default LoginPage
