import { GetServerSideProps } from "next"
import LoginPage from "@modules/auth/pages/LoginPage"

export default LoginPage

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { cookies } = ctx.req

  if (cookies.HSTOKENID) {
    return {
      redirect: {
        destination: "/",
      },
      props: {},
    }
  }

  return { props: {} }
}
