import React from "react"
import clsx from "clsx"
import { FlexBox } from "@components/wrapper"
import { FaTimes } from "react-icons/fa"

interface AlertProps {
  variant: "success" | "danger"
  className?: string
  onClose?: () => void
  closed?: boolean
  children: any
}

export const Alert = ({ variant, onClose, closed, className, children }: AlertProps) => {
  return (
    <div
      className={clsx(
        "text-white rounded relative mb-4 py-3 px-5 justify-between items-center mx-auto",
        variant === "success" && "bg-green",
        variant === "danger" && "bg-red",
        closed ? "hidden" : "flex",
        className
      )}
    >
      <div>{children}</div>
      {onClose && (
        <button onClick={onClose}>
          <FaTimes />
        </button>
      )}
    </div>
  )
}
