import { FlexBox } from "@components/wrapper"
import { HARISENIN_PUBLIC_PAGE_ASSETS } from "@constants/const/pictures"
import { Testimonial } from "@interfaces/school"
import MiscServices from "@lib/api/services/misc.services"
import clsx from "clsx"
import Image from "next/image"
import React, { useEffect, useState } from "react"
import Carousel, { DotProps } from "react-multi-carousel"

const TestimonialCard = ({ value }: { value: Testimonial }) => {
  return (
    <FlexBox
      direction="col"
      justify="between"
      className={clsx(
        "relative sm:py-[18px] sm:px-[26px] sm:h-[13em] bg-white shadow-[0_2px_15px_0_#9aaacf33]",
        "w-full px-7 py-7 mb-1"
      )}
    >
      <div className={clsx("lg:leading-6 sm:h-[5rem] mb-6", "leading-[22px]")}>
        {value.testimony}
      </div>

      <FlexBox justify="between" align="end">
        <FlexBox align="center" className="gap-2.5">
          <div className="rounded w-[60px] h-[60px]">
            <Image
              src={value.testimony_user_picture}
              alt={value.testimony_username}
              width={60}
              height={60}
            />
          </div>
          <div className="text-sm">
            <div className="font-semibold sm:text-lg">{value.testimony_username}</div>
            <div className="text-[#909090] text-sm">{value.testimony_user_as}</div>
          </div>
        </FlexBox>

        <div className="max-w-[35%] h-auto object-contain">
          {value.company && (
            <Image
              height={60}
              width={40}
              src={value.company.company_picture}
              alt={value.company.company_name}
              className="h-auto max-w-[60px] max-h-[40px] object-contain"
            />
          )}
        </div>
      </FlexBox>
    </FlexBox>
  )
}

const CustomDot = ({ active, onClick }: DotProps) => {
  return (
    <button
      className={clsx(
        "wh-3 rounded-full mx-1.5 border-0 transition-all duration-200",
        active ? "bg-blue" : "bg-grey-c4 w-auto rounded-0"
      )}
      onClick={(e) => {
        if (onClick) {
          onClick()
        }
        e.preventDefault()
      }}
    />
  )
}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const LeftPictureRegistrationPage = () => {
  const [testimonials, setTestimonials] = useState<Testimonial[]>([])

  const misc = new MiscServices()

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await misc.getTestimonials("school")

        if (res.isSuccess) {
          const data = res.getValue()
          setTestimonials(data)
        }

        // setIsLoading(false)
      } catch (e: any) {
        // school.bugsnagNotifier(e)
        // setIsLoading(false)
      }
    }

    getData()
  }, [])

  return (
    <>
      <div className={clsx("sm:only:pt-[60px]", "sm:w-3/5")}>
        <div className={clsx("relative overflow-hidden")}>
          <div>
            <Image
              src={`${HARISENIN_PUBLIC_PAGE_ASSETS}/register-asset.jpeg`}
              width={1200}
              height={640}
              alt="home-banner"
              className={clsx("object-cover w-full", "sm:h-[calc(100vh-60px)] h-[152px]")}
              priority
            />

            <div
              className={clsx(
                "absolute top-0 left-0 sm:px-[80px] sm:pt-[90px] sm:bg-[rgba(18,17,17,0.85)] sm:h-full w-full text-white",
                "h-[152px] bg-[rgba(18,17,17,0.85)] py-7.5 px-5"
              )}
            >
              <div className="relative grid sm:gap-[62px]">
                {/* <BasicLink href="/" className="h-full sm-only:absolute right-0">
                  <Image
                    src={`${HARISENIN_PUBLIC_LOGO}/logo_rise-white.png`}
                    alt="harisenin.com-logo"
                    className="sm:wh-12 w-[42px] h-[42px] rounded-full"
                    width={320}
                    height={320}
                  />
                </BasicLink> */}

                <div className="">
                  <h1
                    className={clsx(
                      "sm:text-5xl sm:leading-[68px] font-bold text-white mx-0",
                      "text-2xl "
                    )}
                  >
                    <span className="text-new-green mb-8"> Selamat </span>Datang!                    
                  </h1>
                  <p className="sm:text-xl text-sm">
                    Yuk, eksplor lebih lengkap seputar program kami!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-4 left-16 sm-only:hidden w-[calc(60%-160px)]">
        <Carousel
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          arrows={false}
          showDots
          customDot={<CustomDot />}
          renderDotsOutside
          dotListClass="flex justify-start"
          autoPlay={testimonials.length > 1}
          infinite
          autoPlaySpeed={3000}
          containerClass="sm:pb-5 "
        >
          {testimonials?.map((value, index) => (
            <TestimonialCard value={value} key={value.id} />
          ))}
        </Carousel>
      </div>
    </>
  )
}

export default LeftPictureRegistrationPage
