import KyMethod from "../repositories/KyMethod"

export default class MiscRepositories extends KyMethod {
  protected async getRawAlumnus(query?: any) {
    return this._getData(`/alumnus`, query)
  }

  protected async getRawCertificateInfo(id: string) {
    return this._getData(`/certificates/${id}`)
  }

  protected async getRawTestimonials(type: string) {
    return this._getData(`/testimonials`, { type }, "v3")
  }

  protected async getRawFaqs(type: string) {
    return this._getData(`/faqs`, { type })
  }

  protected async getRawTutors() {
    return this._getData(`/tutors`)
  }

  protected async getRawAlumnusSector() {
    return this._getData(`/category`, { type: "alumni-category" })
  }

  protected async getRawOrderAndAlumnus() {
    return this._getData(`/users/orders-alumni`, {}, "v3")
  }

  async getRawProducts( query?: any) {
    return this._getData("/digital/products", query)
  }
}
